/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from "react";
import cx from "classnames";
import "./App.css";
import { saveAs } from "file-saver";
import { CloudUpload, CloudDownload, Autorenew } from "@material-ui/icons";
import Head from "./components/Head";
import { writeXSL, readXSL } from "./excel";
import useApi from "./hooks/api";
import Button from "./components/Button";
import { Panel, PanelHeader } from "./components/Panel";
import Notification from "./components/Notification";
import notify from "./components/Notification/Notification.service";

import { Box, makeStyles } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { Flex, Text } from "./common.styles";
import If from "./components/If/";

function App() {
  const { translate } = useApi();
  const cxs = useStyles();
  const [translationType, setTranslationType] = useState<string>("FILE");
  const [language, setLanguage] = useState("en-ar");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [appState, setUploadState] = useState<null | any>({
    total: 0,
    done: false,
  });
  const [file, setFile] = useState<null | any>();
  const [translated, settranslated] = useState<any>([]);

  useEffect(() => {
    if (translate.state.error) {
      console.error(translate.state.error);
      notify.show(`Error Uploading: ${translate.state.error.message}`, "error");

      console.timeEnd(`transaltion${file?.name}`);
      setUploadState({ total: 0, done: false });
    }
  }, [translate.state.error]);

  useEffect(() => {
    const data = translate.state.data;
    const isFile = Array.isArray(data);
    if (!appState.done && isFile && data) {
      settranslated([...translated, ...data]);
    }

    if (appState.done && !isFile && data) {
      settranslated([data]);
    }

    if (appState.done && translated.length > 0) {
      let message = `${file.name} with ${translated.length} rows translated`;
      if (!isFile) message = `${file.name} translated`;
      notify.show(message, "success");
    }

    // if (appState.done && translated.length === 0) {
    //   notify.show("Please Try again", "error");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translate.state.data, translate.state.error, appState.done]);

  const handleLanguageChange = (_: any, newLang: string) => {
    setLanguage(newLang);
  };

  const handleTypeChange = (_: any, newType: string) => {
    setTranslationType(newType);
  };

  const handleFileChange = (e: any) => {
    notify.hide();
    setFile(null);

    const label = e.target.parentElement;
    label.classList.add("bg-blue-300");
    setFile(e.target.files[0]);
    setUploadState({ ...appState, total: 0, done: false });
  };

  const handleForm = async (e: any) => {
    e.preventDefault();

    setUploadState({ ...appState, total: 0, done: false });

    if (translationType !== "FILE") {
      const value = e.target.querySelector("#text-to-translate").value;

      translate.hit({
        data: value.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, "<br />"),
        language,
      });

      return;
    }
    console.time(`transaltion${file?.name}`);

    try {
      if (!appState.done) {
        if (file.type === "text/plain") {
          var reader = new FileReader();
          reader.readAsText(file, "UTF-8");
          reader.onload = async (event: any) => {
            translate.hit({
              data: event.target.result,
              language,
              email,
              password,
              info: { file: file.name, chunks: 0, index: 0 },
            });

            await timer(1000);

            setUploadState({
              ...appState,
              done: true,
            });
          };

          return;
        }

        const xsltojson = (await readXSL(file)) as any;
        const columnLength = xsltojson[2].length >= 5;

        const splitCount = columnLength ? 30 : xsltojson.length < 100 ? 5 : 100;
        const chunk = chunkArray(xsltojson, splitCount);

        setUploadState({ ...appState, total: xsltojson.length });

        for (let index = 0; index < chunk.length; index++) {
          translate.hit({
            data: chunk[index],
            language,
            email,
            password,
            info: { file: file.name, chunks: chunk.length, index: index + 1 },
          });
          console.log("api call done", index + 1, "of ", chunk.length);
          await timer(columnLength ? 70000 : 60000);
        }
        console.timeEnd(`transaltion${file?.name}`);

        setUploadState({ ...appState, done: true });
      }
    } catch (e) {
      console.error({ error: e });
    }
  };

  const onDownload = (e: any) => {
    e.preventDefault();
    const write = async () => {
      const isText = file.type === "text/plain";
      console.log(translated, isText);

      const fileData = await (isText
        ? Promise.resolve(translate.state.data)
        : writeXSL(translated));

      var blob = new Blob([fileData]);
      saveAs(blob, getName(file.name, language));
    };
    write();
  };

  var percentage = ((translated.length / appState.total) * 100).toFixed(2);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={"column"}
      mx={2}
    >
      <Head />
      <Notification />

      <Panel
        loading={
          translate.state.pending ||
          (translated.length > 0 && translated.length <= appState.total)
        }
        width={[1, 1, 2 / 5]}
        m={2}
      >
        {appState.total && file?.name && !appState.done ? (
          <Flex className={cxs.loadingProgress} alignItems="center">
            <Text>
              {translated.length} of {appState.total} ({percentage}
              %)
            </Text>
          </Flex>
        ) : (
          false
        )}
        <PanelHeader height="64px" py="10px" pr="10px" pl={3} color="primary">
          <Flex
            flexDirection="row"
            alignItems="center"
            height={1}
            justifyContent="space-between"
          >
            <Box mr={2} fontSize={16} fontWeight={600}>
              Translation App
            </Box>
            <ToggleButtonGroup
              size="medium"
              value={language}
              exclusive
              onChange={handleLanguageChange}
              className="bg-white"
            >
              <ToggleButton value="en-ar">
                <Text>EN-AR</Text>
              </ToggleButton>
              <ToggleButton value="ar-en">
                <Text>AR-EN</Text>
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              size="medium"
              value={translationType}
              exclusive
              onChange={handleTypeChange}
              className="bg-white"
            >
              <ToggleButton value="FILE">
                <Text>File</Text>
              </ToggleButton>
              <ToggleButton value="TEXT">
                <Text>Text</Text>
              </ToggleButton>
            </ToggleButtonGroup>
          </Flex>
        </PanelHeader>
        <Box p={3} component="form" onSubmit={handleForm}>
          <Flex justifyItems="center" alignItems="center" display="flex" mt={1}>
            <If test={translationType !== "FILE"}>
              <input
                className="w-full px-5 py-3 mb-4 leading-tight text-gray-700 border rounded-lg shadow-lg cursor-pointer justify-items-center text-blue border-blue hover:bg-blue"
                id="text-to-translate"
                name="text"
                type="text"
                required
                placeholder="Content"
              />

              <If test={translate.state.data}>
                <input
                  className="w-full px-5 py-3 mb-4 leading-tight text-gray-700 rounded-lg shadow-s cursor-pointer justify-items-center text-blue border-blue hover:bg-blue"
                  type="text"
                  readOnly
                  placeholder="Content"
                  value={translate.state.data}
                />
              </If>

              <Flex
                flexDirection="row"
                justifyItems="center"
                alignItems="center"
                justifyContent="center"
              >
                <If test={!appState.done}>
                  <Button color={"blue"} className="w-1/2" type="submit">
                    Translate
                  </Button>
                </If>
              </Flex>
            </If>

            <If test={translationType === "FILE"}>
              <If test={!appState.done}>
                <label
                  htmlFor="file-upload-input"
                  className={cx(
                    "flex flex-col  w-full items-center px-4 py-6 pt-3 mb-4 tracking-wide uppercase bg-white border rounded-lg shadow-lg cursor-pointer translation-label justify-items-center text-blue border-blue hover:bg-blue",
                    { "bg-blue-300": file?.name }
                  )}
                >
                  <CloudUpload />
                  <span className="mt-2 text-base leading-normal file-name">
                    {file?.name || `Select file (.xlsx, .txt)`}
                  </span>
                  <input
                    type="file"
                    id="file-upload-input"
                    name="translation"
                    className="hidden file-upload"
                    required
                    onChange={handleFileChange}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
                  />
                </label>
              </If>

              <If test={!appState.done}>
                <input
                  className="w-full px-5 py-3 mb-4 leading-tight text-gray-700 border rounded-lg shadow-lg cursor-pointer justify-items-center text-blue border-blue hover:bg-blue"
                  id="email"
                  name="email"
                  type="email"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  required
                  placeholder="Email (for tracking and reporting)"
                />
                <input
                  className="w-full px-5 py-3 mb-4 leading-tight text-gray-700 border rounded-lg shadow-lg cursor-pointer justify-items-center text-blue border-blue hover:bg-blue"
                  id="password"
                  name="Password"
                  type="password"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setUploadState({ ...appState, total: 0, done: false });

                    setPassword(event.target.value);
                  }}
                  required
                  placeholder="password"
                />
              </If>

              <Flex
                flexDirection="row"
                justifyItems="center"
                alignItems="center"
                justifyContent="center"
              >
                <If test={!appState.done}>
                  <Button color={"blue"} className="w-1/2" type="submit">
                    Upload
                  </Button>
                </If>

                <If test={!translate.state.error && appState.done}>
                  <Button
                    color={"green"}
                    onClick={onDownload}
                    className="w-1/2"
                  >
                    Download
                  </Button>
                </If>

                <If test={translate.state.error || appState.done}>
                  <Button
                    color={"red"}
                    className="w-1/4"
                    onClick={() => document.location.reload()}
                  >
                    <Autorenew /> RESET
                  </Button>
                </If>
              </Flex>
            </If>
          </Flex>
        </Box>
      </Panel>
      <div className="mt-2 mb-2 text-center">
        <a
          className="font-bold text-blue-800 hover:underline hover:text-blue-700"
          href="/sample"
        >
          <CloudDownload /> Download Template
        </a>
      </div>

      <Text>Text Support for quick one-off translations</Text>
    </Box>
  );
}

const getName = (fileName: string, language: string = "en-ar") => {
  const [name, extension] = fileName.split(".");
  const id = Math.floor(Math.random() * 1000);
  return `${name}-${id}-${language}.${extension}`;
};

export const timer = (ms: any) =>
  new Promise((res) => {
    console.log(ms / 1000, "sec wait...");
    setTimeout(res, ms);
  });

const useStyles = makeStyles((theme: any) => ({
  loadingProgress: {
    zIndex: 9999,
    position: "absolute",
    left: "0",
    right: "0",
    textAlign: "center",
    top: "50%",
    background: "white",
  },
}));

export default App;

function chunkArray(myArray: any, chunk_size: number) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}
