import Excel from "exceljs";
import sanitizeHtml from "sanitize-html";
export async function writeXSL(data: any) {
  const excel = new Excel.Workbook();
  const worksheet = excel.addWorksheet("sheet");
  // worksheet.columns = [
  //   { header: "id", key: "id", width: 100 },
  //   { header: "name", key: "name", width: 100 },
  //   { header: "description", key: "description", width: 200 },
  // ] as any;

  for (let row = 0; row <= data.length; row++) {
    const rowData = data[row] ? data[row] : ["", ""];

    const clean = rowData.map((item: any) =>
      String(item).replaceAll(`style=";text-align:right;direction:rtl"`, "")
    );

    worksheet.addRow(clean);
  }

  const buffer = await excel.xlsx.writeBuffer();
  return buffer;
}

export async function readXSL(data: any) {
  const content = data;
  const excel = new Excel.Workbook();
  const wb = await excel.xlsx.load(content);
  const worksheet = wb.getWorksheet(1);
  const totalRows = worksheet.actualRowCount;

  const result = [] as any;

  for (let row = 1; row <= totalRows; row++) {
    const rowdata = worksheet.getRow(row);

    const cellData = [] as any;
    // max to 10 columns
    for (let cell = 1; cell <= 10; cell++) {
      const one = (rowdata.getCell(cell).value || "-") as any;
      if (one && one.richText) {
        cellData.push(decodeString(one.richText[0].text || "-"));
      } else {
        cellData.push(decodeString(one || "-"));
      }
    }

    if (cellData.length > 0) {
      result.push(cellData);
    }
  }

  return result;
}

function decodeString(text: string) {
  if (typeof text === "string") {
    var r = /\\u([\d\w]{4})/gi;
    const result = text.replace(r, function (match: any, grp) {
      return String.fromCharCode(parseInt(grp, 16));
    });

    const x = sanitizeHtml(result);
    return x;
  }

  return text;
}
