import { useFetch } from "./index";

export const useApi = () => {
  const translate = useFetch((data) => ({
    url: `/translate`,
    method: "POST",
    data,
  }));

  return { translate };
};

export default useApi;
