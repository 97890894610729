import React, { Component } from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import notify from "./Notification.service";
import Alert from "@material-ui/lab/Alert";
import { Box } from "@material-ui/core";

interface INotifystate {
  show: boolean;
  content: any;
  type: "error" | "success" | "info" | "warning" | undefined;
  onClose: any;
}

export const CustomAlert = withStyles((theme: Theme) => ({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}))(Alert);

class Notification extends Component<any> {
  unlisten: any;
  constructor(props: any) {
    super(props);
    notify.register(this);
  }

  state: INotifystate = {
    show: false,
    content: null,
    type: "error",
    onClose: () => {},
  };

  show = (content: any, type = "error", onClose = () => {}) => {
    this.setState({ content, show: true, type, onClose });
  };

  hide = () => {
    this.setState({ show: false });
  };

  componentWillUnmount() {
    if (this?.unlisten) this.unlisten();
  }

  render() {
    const { show, content, type } = this.state;

    if (!show) return null;

    return (
      <Box p={2} width={[1, 1, 2 / 5]} mx="auto" my={0}>
        {Array.isArray(content) ? (
          content.map((message, key) => (
            <CustomAlert severity={type} key={`notification-${type}-${key}`}>
              {message}
            </CustomAlert>
          ))
        ) : (
          <CustomAlert severity={type}>{content}</CustomAlert>
        )}
      </Box>
    );
  }
}

export default Notification;
