import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import LoadingBox from "../LoadingBox/LoadingBox";

interface Props extends BoxProps {
  children: React.ReactNode;
  loading?: boolean;
}

export default function Panel({ children, loading = false, ...rest }: Props) {
  return (
    <Box
      borderRadius="8px"
      bgcolor="white"
      overflow="hidden"
      boxShadow="0 2px 4px 0 rgba(0,0,0,0.1)"
      position="relative"
      {...rest}
    >
      {children}
      {loading && <LoadingBox loading={loading} />}
    </Box>
  );
}
