import React from "react";
import { Button } from "@material-ui/core";
import cx from "classnames";
export default function Buttons({
  children,
  color,

  className,
  disabled = false,
  ...props
}: any) {
  const c = disabled ? "gray" : color;
  return (
    <Button
      className={cx(
        `py-2 mx-1 font-bold text-white transition duration-200 bg-${c}-600 border-b-4 border-${c}-700 rounded shadow-lg hover:bg-${c}-700 hover:shadow-xl submit-button`,
        className
      )}
      {...props}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
