import React from "react";
import { Logo } from "./Icons";
export default function Head() {
  return (
    <section>
      <div className="logo-container">
        <Logo />
      </div>
    </section>
  );
}
